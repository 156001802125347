.analytics {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: 0.04em;
    color: #DFDEDE;
}

.boderstyle {
    border: 0.7px solid #D1CFD7;
}


.date-picker {

    border: 0.93107px solid #ECE9F1;
    border-radius: 3.72428px;
    padding: 5px;
    color: #11263C;
}

.amounthome {
    background: #807F7F;
    box-shadow: 0px 0.93107px 9.3107px rgba(70, 70, 70, 0.14);
    border-radius: 9.3107px;
    padding: 20px;
}

.dottedbody {
   
    border-radius: 3.72428px;
    padding: 3px;

}

.dottedbody>span {
    font-family: 'Nunito' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    line-height: 20px !important;
   

}


.beforep > p{
    width: 100%;
    max-width: 100px;
}



.unverified{
    background: #FFECB3;
    border-radius: 100px;
    padding:  5px 10px;
    color: #ED6700;
    width: 100%;

}

.banned{
    background: #8DA4EC;
    border-radius: 100px;
    padding:  5px 10px;
    
    padding:  5px 10px;
    /* width: 100%;
    max-width: 50%; */
    /* text-align: center; */
}


/* .beforep{
    width: 100%;
    text-align: center;
} */

.activestatus {
  
    border-radius: 100px;
    padding:  5px 10px;
    /* width: 100%;
    max-width: 50%; */
    /* text-align: center; */
 
}

.unknown{
   
    border-radius: 100px;
    padding:  5px 10px;
}

/* table >thead >tr > th:nth-child(5) {
   text-align: center !important;
} */


.status{
    background: #11263C !important;
}


.shadow-bxshadow-container {

    padding: 0px !important;
    margin: 0px !important;
}

.borderbtm {
   
}


.bordertop {
    
}


.boldtitle {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 800 !important;
    font-size: 30px !important;
    line-height: 36px !important;
    letter-spacing: 0.04em !important;

    color: #333333 !important;
}



.sidecontent {
    font-family: 'Inter' !important;
    font-style: normal !important;

}


.packagename,
.packagepercent {
    font-family: 'Nunito' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 17.2px !important;
    line-height: 34px !important;
    letter-spacing: 0.716667px !important;
    text-transform: uppercase !important;

    color: #868E96 !important
}

.MuiTableRow-root {
    color: inherit !important;
    display: table-row !important;
    outline: 0 !important;
    vertical-align: middle !important;
}


table .MuiTableRow-root:nth-child(2n) {
    background-color: #FCFBFB !important;


}




table .MuiTableRow-root {

    font-family: 'Nunito' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;


    color: #4A505E !important
}

table th {
    background: #FCFBFB !important;
    border-radius: 17.3749px 17.3749px 0px 0px !important;
}


.flexwidth {
    /* background: #c4c8cc !important;
    width: 100%;
    max-width: 500px;
    flex-wrap: wrap; */
    position: absolute;
    top: 20px;
    z-index: 1;
}
/* CSS styles for the logout modal and buttons */

/* Sidebar container */
.sidebar-container {
    /* Add your sidebar container styles here */
  }
  
  /* Logout button */
  .logout-button {
    cursor: pointer;
    /* Add styles for your logout button */
  }
  
  /* Logout Modal */
  .logout-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9); /* White background with opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    border: 2px solid #FF7900; /* Change border color to orange */
  }
  
  
  /* Modal title */
  .modal-content h2 {
    margin-bottom: 10px;
  }
  
  /* Modal message */
  .modal-content p {
    margin-bottom: 20px;
  }
  
  /* Modal buttons container */
  .modal-buttons {
    display: flex;
    justify-content: center;
  }
  
  /* Logout and Cancel buttons */
  .modal-buttons button {
    cursor: pointer;
    margin: 0 10px;
    padding: 8px 20px;
    border: none;
    border-radius: 4px;
    outline: none;
    transition: background-color 0.3s ease;
  }
  
  /* Style for the Logout button */
  .logout-confirm-button {
    background-color: #FF7900;
    color: white;
  }
  
  /* Style for the Cancel button */
  .logout-cancel-button {
    background-color: #E0E0E0;
    color: #333333;
  }
  
  /* Hover effect for buttons */
  .logout-confirm-button:hover,
  .logout-cancel-button:hover {
    opacity: 0.9;
  }
  


@media (min-width: 768px) {
    #sidebar>div {
        /* background: aqua; */
    }
}



@media only screen and (max-width: 728) {
    .piemobile {
        display: none !important;
    }
}