@tailwind base;
@tailwind components;
@tailwind utilities;





/* .MuiButtonBase-root,
.MuiIconButton-root,
.MuiIconButton-colorInherit,
.MuiIconButton-sizeMedium,
.css-zylse7-MuiButtonBase-root-MuiIconButton-root {
    position: relative !important;
    background: forestgreen !important;
    z-index: 12000 !important;
} */

.css-zylse7-MuiButtonBase-root-MuiIconButton-root {
    position: relative !important;
    /* background: forestgreen !important; */
    z-index: 12000 !important;
}


.MTableToolbar-searchField-24 {
    position: relative !important;
    /* background: forestgreen !important; */
    z-index: 13000 !important;
}


.MuiFormControl-root,
.MuiTextField-root,
.MTableToolbar-searchField-262,
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    position: relative !important;
    /* background: forestgreen !important; */
    z-index: 13000 !important;
}


.responsivepie>.recharts-responsive-container {
    width: auto !important;
}

.responsivepie>.recharts-responsive-container>.flex {

    width: 200px !important;

}

.responsivepie {
    display: flex;

    align-items: center;

}

.MTableHeader-header-13,
.MTableHeader-header-503 {
    z-index: 0 !important;
}




.css-1x51dt5-MuiInputBase-input-MuiInput-input {
    max-width: fit-content !important;
}


.myImage {
    width: 100%;
    max-width: 200px;
}

.innerimg>img {
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: 100%;
    max-height: 200px;
    max-width: 200px;
}

/* .activestatus{
    display: flex;
    justify-content: center;
} */






.css-zw3mfo-MuiModal-root-MuiDialog-root {
    position: fixed;
    z-index: 4000 !important;

}



@media (max-width: 800px) {
    .pieandfreemium {
        display: block !important;
    }

    .responsivepie {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }


    .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
        flex-wrap: wrap !important;
    }


    .toflexmobile {

        width: 100% !important;
        max-width: 400px !important;
        flex-wrap: wrap !important;
    }

    .toptobottom {
        margin-top: 100px;
    }


}


.zindexproperty {
    z-index: 4000;
}



.MuiToolbar-root,
.MuiToolbar-gutters,
.MuiToolbar-regular,
.MTableToolbar-root-33,
.css-hyum1k-MuiToolbar-root {
    position: relative;
    z-index: 1;
}