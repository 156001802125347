.lime {
    border: 1px solid rgb(231, 138, 72) !important;
}

.two {
    border: 1px solid rgb(252, 164, 76) !important;
}


.three {
    border: 1px solid rgb(203, 134, 65) !important;
}

.four {
    border: 1px solid rgb(239, 122, 5) !important;
}


.five {
    border: 1px solid rgb(223, 142, 62) !important;
}


.six {
    border: 1px solid rgb(171, 92, 13) !important;
}

.seven {
    border: 1px solid rgb(181, 93, 5) !important;
}

.eight {
    border: 1px solid rgb(218, 145, 71) !important;
}


.nine {
    border: 1px solid rgb(214, 108, 3) !important;
}