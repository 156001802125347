#sidebar {
    --tw-translate-x: -100%;

}

#menu-close-icon {
    display: none;
}

#menu-open:checked~#sidebar {
    --tw-translate-x: 0;
}

#menu-open:checked~* #mobile-menu-button {
    /* background-color: rgba(31, 41, 55, var(--tw-bg-opacity)); */
    background-color: blueviolet;
}

#menu-open:checked~* #menu-open-icon {
    display: none;
}

#menu-open:checked~* #menu-close-icon {
    display: block;
}


.bgclass {
    background: gray !important;
}


/* #content{
    margin-right: 200px;
} */


@media (min-width: 860px) {
    #sidebar {
        --tw-translate-x: 0;
    }

    /* .bg-sidebar{
        padding-left: 0px !important;
    } */
}


/* @media only screen and (max-width: 989px) and (min-width: 820px) {
    #sidebar {
        --tw-translate-x: 0;

        margin-right: 0px !important;
        padding-right: 0px !important;
        padding-left: 0px !important;
    }


    
} */