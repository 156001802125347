.dialogArea{
    padding: 20px;
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    background-color: white;
    position: relative;
}
.closeIcon{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}
.iconArea{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: var(--main);
    display: flex;
    align-items: center;
    justify-content: center;
}
.iconAreaError{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #FB4B4B;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleArea{
    font-weight: 600;
    font-size: 24px;
    color: #7C8691;
    text-align: center;

}
.messageArea{
    font-weight: 400;
    font-size: 16px;
    color: #b4b8bc;
    text-align: center;
}
.sub_message{
    font-weight: 400;
    font-size: 14px;
    color: #0456a8;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
}
.btnArea{
    background: #F1F5F8;
    width: 320px;
    padding: 10px 20px;
}
.btnAreaError{
    background: #F1F5F8;
    width: 320px;
    padding: 10px 20px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
}



.create_input{
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.create_input .input_name {
    border: 1px solid #c1c2c9;
    border-radius: 4px;
    color: #8f92a1;
    outline: none;
    padding: 17px 20px;
    max-width: 40%;
    overflow-x: hidden;
}


.parentdiv{
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    margin-bottom: 3%;
}


.css-zw3mfo-MuiModal-root-MuiDialog-root {
    position: fixed;
    z-index: 4000 !important;

}



@media only screen and (max-width: 768px) {
    .create_input .input_name  {
       
    max-width: 70%;
   
    overflow-x: hidden;
    overflow: hidden;
    }
  }