.editorClassName {
    padding: 0px 20px;
    height: 250px;
    /* background-color: aqua; */
}

.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
    height: calc(100% - 17px) !important;
}