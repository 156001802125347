.Upgrade_enable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;

}

.form_input>input {
    width: 100%;
    text-indent: 1px;
    margin: 0px 0 20px 0;
    border: 1px solid #FF7900;
}


.form_btn>input {
    padding: 5px;
    background: #FF7900;
    color: white;
    border: 1px solid #FF7900;
    border-radius: 4px;
}


.form_btn>select {
    text-indent: 1px;
    margin: 20px 0;
    border: 1px solid #FF7900;

}


.form_input>textarea {
    text-indent: 1px;
    /* margin: 20px 0; */
    border: 1px solid #FF7900;
    width: 100%;
}


.aud_enb {
    font-weight: bold;

}

.parentdiv>select {
    /* text-indent: 1px;
        */
    border: 1px solid #FF7900;
    margin: 0px 0 20px 0;

}


.top_btn {
    background: #FF7900;
    color: #ffffff;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 10px;
}

.close_btn {
    cursor: pointer;
}


.features_tag {
    background: white;
    color: #FF7900;
    margin: 12px 0;
}
.datepicker {
    background-color: transparent !important; 
    
  }