.login_container{
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #FF7900;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    
}
.login_container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
.login_left{
    flex: 3;
    display: flex;
    /* align-items: flex-end; */
    gap: 30px;
    padding: 50px 20px;
    position: relative;
    flex-direction: column;
}

.login_left > img{
    cursor: pointer;
}


.object_logo{
    width: 300px;
}
.object_svg2{
    position: absolute;
    bottom: 0;
    right: -100px;
    width: 400px;
}
.textCont h1{
    font-size: 72px;
    font-weight: 800;
    color: white;
    width: 100%;
    max-width: 90%;
}

.login_right{
    flex: 4;
    display: flex;
    justify-content: flex-start;
    padding: 50px 20px;
    background-color: white;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    overflow-y: scroll;
}
.right_container{
    width: 100%;
    max-width: 700px;
}

.login_head{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    width: 100%;
}
.login_head h1{
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    margin: 0;
}
.login_head p{
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    text-align: center;
    margin: 0;
}
.login_head span{
    color: #FF7900;
    cursor: pointer;
    user-select: none;
}
.form_container{
    padding: 30px;
    margin-left: 20%;
}
@media screen and (max-width: 550px) {
    .form_container{
        padding: 20px 0;
        width: 100%;
    }
    .showDesktop{
        display: none;
    }
    .showMobile{
        display: block;
    }
    .login_left{
        display: none;
    }
    .login_right{
        flex: 1;
        justify-content: center;
        padding: 50px 20px;
        border-radius: 0;
    }
    .login_head h1{
        font-size: 23px;
        font-weight: 600;
        line-height: 32px;
    }
    .login_head p{
        font-size: 12px;
        font-weight: 300;
        line-height: 15px;
    }
}
@media only screen and (max-width: 1024px) {
    /* Adjust tablet-specific styles */
    .right_container {
      /* Update styles to fit smaller screens */
    }
  }

  @media only screen and (max-width: 768px) {
    /* Mobile styles */
    .login_left {
      display: none; /* Hide left section for mobile */
    }
    .right_container {
        background-color: white; /* Set back white background */
        /* Adjust text sizes or other properties here */
      }
    }